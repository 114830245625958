<template>
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
          <div class="col-12 col-md-12 m-0 p-0" style="margin-top: -5px !important;">
            <DivisorColor  :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`"/>
          </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="6">
            Negociaciones Comixtas
          </v-col>
          <v-col cols="12" sm="12" md="6" class="d-none">
            <v-btn
              color=""
              dark
              class="mb-2 float-right blue-grey darken-2"
              @click="modalNuevo"
            >
              Registrar negociación comixta
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="items"
          :loading="tableLoading"
          :search="filtro"
          :header-props="{ 
            sortByText: 'Ordenar por'
          }"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="6" xs="12">
                  <v-text-field
                    autocomplete="off"
                    class="required"
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Descripción de la negociación, id"
                    maxlength="200"
                  >
                    <v-icon slot="append" color="black">
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>

                <v-col cols="6" md="6" sm="6" xs="12">
                  <v-select
                    v-model="estado"
                    :items="estados"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col>

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.titulo }}</td>
              <td>{{ item.descripcion }}</td>
              <td>{{ item.modalidad }}</td>
              <td>{{ item.pais }}</td>
              <td>{{ item.fechaAprobacion }}</td>
              <td>
                <v-chip
                  class="ma-2 font-weight-medium"
                  label
                  :color="
                    item.estadoId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadoId === 2
                      ? 'blue lighten-4'
                      : item.estadoId === 6
                      ? 'cyan lighten-4'
                      : item.estadoId === 3
                      ? 'teal lighten-4'
                      : item.estadoId === 4
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadoId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadoId === 2
                      ? 'blue lighten-1'
                      : item.estadoId === 6
                      ? 'cyan darken-1'
                      : item.estadoId === 3
                      ? 'teal lighten-1'
                      : item.estadoId === 4
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estado }}
                </v-chip>
              </td>
              <td>
                <!--<v-btn
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-eye</v-icon> Detalle
                </v-btn>-->

                <v-btn
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosItem(item.id)"
                >
                  <v-icon left>mdi-eye</v-icon> Visualizar
                </v-btn>

                <!--<v-btn
                  v-if="item.estadoId === 1"
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="prepararEnvio(item.id)"
                >
                  <v-icon left>mdi-send</v-icon> Enviar
                </v-btn>-->

                

                

                <v-btn v-if="item.estadoId==1 || item.estadoId==2 || item.estadoId === 3"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="descargarArchivo(item.pathDocumentoFormalizacion)"
                >
                 <v-icon left>mdi-file-download-outline</v-icon> Documento respaldo
                </v-btn>

                <!-- <v-btn
                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                    small
                    depressed
                    color="blue-grey lighten-5"
                    @click="mostrarModalRolesActores(item.id, item.modalidadId)"
                    >
                    <v-icon left>mdi-earth</v-icon> Registrar Roles y Actores
                </v-btn> -->

                <v-btn
                  v-if="(item.estadoId === 1 || item.estadoId === 3) && item.asignacionActores != 0"
                  class="
                    ma-2
                    btn-bg-light
                    blue-grey--text
                    lighten-2--text
                    font-weight-medium
                    text-capitalize
                  "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  :to="{
                    name: 'negociacion-comixta-iniciativas',
                    params: {
                      negociacionId: $CryptoJS.AES.encrypt(
                        item.id.toString(),
                        'KEYADMINCNS2022'
                      ).toString()
                    }
                  }"
                >
                  <v-icon left>mdi-file</v-icon> Completar información
                </v-btn>

                <v-btn
                  v-if="
                    item.estadoId === 3 ||
                      item.estadoId === 4 ||
                      item.estadoId === 5 ||
                      item.estadoId === 6
                  "
                  class="
                      ma-2
                      btn-bg-light
                      blue-grey--text
                      lighten-2--text
                      font-weight-medium
                      text-capitalize
                    "
                  small
                  depressed
                  color="blue-grey lighten-5"
                  :href="
                    `https://rpts.segeplan.gob.gt:8080/jasperserver/rest_v2/reports/reports/SICOOPERA/Resumen.pdf?NegociacionId=${item.id}&j_password=reporte&j_username=reportes`
                  "
                  target="_blank"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon> Descargar Reporte
                </v-btn>
                <!--<v-menu
                  top
                  :close-on-click="true"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="orange darken-2"
                      icon
                      fab
                      small
                      v-bind="attrs"
                      v-on="on"
                      v-if="item.asignacionActores === 0"
                    >
                      <v-icon color="orange darken-2">info</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title>Debe agregar roles y actores para poder registrar iniciativas.</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>-->
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <!-- <template v-slot:html>
            {{ code4.html }}
          </template>
          <template v-slot:js>
            {{ code4.js }}
          </template> -->
    </v-card>

    <!--begin:: dialog suspension -->
    <v-dialog v-model="dialogEnvio" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Enviar negociación

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEnvioLoading"
            persistent
            transition="scroll-y-transition"
            @click="dialogEnvio = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <p class="text-subtitle-1">
                Está seguro de enviar la negociación seleccionada?
              </p>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="dialogEnvio = false"
            :disabled="btnEnvioLoading"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="blue-grey darken-2"
            text
            @click="enviarNegociacion"
            :loading="btnEnvioLoading"
          >
            Sí, enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--end: dialog-->

    <!--begin:: modal registro-->
    <v-dialog
      v-model="dialogRegistro"
      max-width="900"
      transition="scroll-y-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }}

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="
              dialogRegistro = false;
              resetForm();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">
          <v-form
            ref="form"
            v-on:submit.prevent="registrarItem"
            v-model="validForm"
          >
            <v-row>
               <!--inicio:: campo -->
               <v-col cols="12" md="12" sm="12" class="pt-6 pb-0">
                <v-text-field
                  dense
                  filled
                  v-model="datosItem.titulo"
                  :disabled="true"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Modalidad"
                  :rules="[required('Título')]"
                  menu-props="auto"
                >
                </v-text-field>
              </v-col>
              <!--fin:: campo-->

               <!--inicio:: campo -->
               <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                <v-textarea
                  dense
                  filled
                  v-model="datosItem.descripcion"
                  :disabled="true"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Modalidad"
                  :rules="[required('Descripción')]"
                  menu-props="auto"
                >
                </v-textarea>
              </v-col>
              <!--fin:: campo-->

               <!--inicio:: campo -->
               <!-- <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.modalidadId"
                  :items="modalidades"
                  :loading="ddModalidadesLoading"
                  :disabled="true"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Modalidad"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('Modalidad')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col> -->
              <!--fin:: campo-->
              <!--inicio:: campo -->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.modalidadId"
                  :items="modalidades"
                  :loading="ddModalidadesLoading"
                  :disabled="true"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Modalidad"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('Modalidad')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo -->
              <!-- <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                <v-select
                  dense
                  filled
                  v-model="datosItem.paisId"
                  :items="paises"
                  :loading="ddPaisesLoading"
                  class="required"
                  color="blue-grey lighten-2"
                  label="Pais"
                  item-text="nombre"
                  item-value="id"
                  :rules="[selectRequired('País')]"
                  menu-props="auto"
                >
                </v-select>
              </v-col> -->
              <!--fin:: campo-->

             

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="fechaAprobacion"
                  :disabled="true"
                  label="Fecha de la suscripción"
                  :rules="[
                    required('Fecha de la suscripción'),
                    minLength('Fecha de la suscripción', 10),
                    dateFormat('Fecha de la suscripción')
                  ]"
                  maxlength="10"
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  :disabled="true"
                  v-model="iniciaVigencia"
                  label="Fecha inicio de vigencia"
                  :rules="[
                    required('Fecha inicio de vigencia'),
                    minLength('Fecha inicio de vigencia', 10),
                    dateFormat('Fecha inicio de vigencia')
                  ]"
                  maxlength="10"
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->

              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  :disabled="true"
                  v-model="finalizaVigencia"
                  label="Fecha finalización de vigencia"
                  :rules="[
                    required('Fecha finalización de vigencia'),
                    minLength('Fecha finalización de vigencia', 10),
                    dateFormat('Fecha finalización de vigencia')
                  ]"
                  maxlength="10"
                  v-mask="'##/##/####'"
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->
            
              <!--inicio:: campo-->
              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                <v-text-field
                  dense
                  filled
                  autocomplete="off"
                  class="required"
                  color="blue-grey lighten-2"
                  v-model="datosItem.intrumentoFormalizacion"
                  :disabled="true"
                  label="Nombre del documento de formalización"
                  :rules="[
                    required('Nombre del documento de formalización'),
                    minLength('Nombre del documento de formalización', 5),
                    maxLength('Nombre del documento de formalización', 300)
                  ]"
                  maxlength="300"
                 
                ></v-text-field>
              </v-col>
              <!--fin:: campo-->

              <v-col cols="12" md="6" sm="6" xs="12" class="pt-0" v-if="accion === 2">
                <v-btn
                  class="white--text ma-2 mt-1 mb-0 ml-0"
                  medium
                  tile
                  color="light-blue-502"
                  @click="descargarArchivo(datosItem.pathDocumentoFormalizacion)"
                >
                  <v-icon left>mdi-cloud-download</v-icon> Descargar inst. formalización
                </v-btn>
              </v-col>
              
            </v-row>

            <v-row v-if="0<0">
              <!--inicio:: campo -->
              <v-col cols="12" md="8" sm="6" xs="12" class="pt-0 pb-0">
                <template>
                  <v-file-input
                    id="respaldoFile"
                    dense
                    filled
                    placeholder="Seleccionar pdf del documento"
                    label="Instrumento de formalización"
                    append-icon="mdi-file-pdf-outline"
                    prepend-icon=""
                    accept="application/pdf,image/jpeg"
                    @change="onFileChange"
                    :rules="[accion===1 ? fileRequired('Instrumento de formalización') : true]"
                    :show-size="1000"
                    ref="respaldoFile"
                    :class="accion===1 ? `required` : ``"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" small label color="primary">
                        {{ text }}
                      </v-chip>
                      <span
                        v-else-if="index === 2"
                        class="
                                text-overline
                                grey--text
                                text--darken-3
                                mx-2
                                "
                      >
                        +{{ files.length - 2 }} archivo(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-col>
              <!--fin:: campo-->
              <!-- <v-col cols="12" md="4" sm="6" xs="12" class="pt-0" v-if="accion === 2">
                <v-btn
                  class="white--text ma-2 mt-1 mb-0 ml-0"
                  medium
                  tile
                  color="light-blue-502"
                  @click="descargarArchivo(datosItem.pathDocumentoFormalizacion)"
                >
                  <v-icon left>mdi-cloud-download</v-icon> Descargar inst. formalización
                </v-btn>
              </v-col> -->
              
            </v-row>
            <v-row v-if="0>0">
              <v-col cols="12">
                 <v-spacer></v-spacer>
                  <!--<v-btn
                    color="grey lighten-5"
                    elevation="0"
                    class="mb-2 float-right grey lighten-5"
                    @click="
                      dialogRegistro = false;
                      resetForm();
                    "
                    :disabled="btnRegistroLoading"
                  >
                    Cancelar
                  </v-btn>-->
                  <!--:disabled="!validDocForm" type="submit"-->
                  <v-btn
                    color="light-blue-502"
                    class="white--text mb-2 float-right mr-2"
                    type="submit"
                    :elevation="0"
                    :disabled="!validForm"
                    :loading="btnRegistroLoading"
                  >
                    {{btnRegistroText}}
                  </v-btn>
              </v-col>
            </v-row>
          </v-form>


          <v-card outlined class="mt-4" v-if="idNegociacionRegistrada != 0">
            <v-card-text>

                <v-row>
                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-3">
                        <v-subheader class="text-h6 font-weight-black black--text">
                             Roles y actores
                        </v-subheader>
                    </v-col>

                </v-row>

                <v-form ref="form" v-on:submit.prevent="registrarActor" v-model="validFormActores" v-if="0<0">
                  <v-row class="mt-6">
              
                    <!--inicio:: campo-->
                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                      <v-select
                        dense
                        filled
                        v-model="datosRolActor.rolesActorId"
                        :items="roles"
                        color="blue-grey lighten-2"
                        class="required"
                        label="Seleccionar Rol"
                        item-text="nombre"
                        item-value="id"
                        :rules="[selectRequired('País')]"
                        menu-props="auto"
                      >
                      </v-select>
                    </v-col>
                    <!--fin:: campo-->

                    <!--inicio:: campo-->
                    <v-col cols="12" md="6" sm="12" class="pt-0 pb-0">
                      <v-select
                          dense
                          filled
                          v-model="datosRolActor.paisId"
                          :items="paises"
                          :loading="ddPaisesLoading"
                          class="required"
                          color="blue-grey lighten-2"
                          label="Pais"
                          item-text="nombre"
                          item-value="id"
                          :rules="[selectRequired('País')]"
                          menu-props="auto"
                        >
                        </v-select>
                    </v-col>
                    <!--fin:: campo-->

                    <v-col cols="12" md="12" sm="12" class="pt-0 pb-0">
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-0 white--text"
                        medium
                        type="submit"
                        color="light-blue darken-2"
                        :disabled="!validFormActores"
                        :loading="btnRegistroRolActorLoading"
                        
                      >
                        <v-icon left>mdi-plus</v-icon> Agregar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>

                <v-row>

                    <v-col cols="12" md="12" sm="12">
                      <template>
                        <v-data-table
                          :headers="headersActores"
                          :items="rolesActores"
                          :items-per-page="5"
                          :loading="tableRolesActoresLoading"
                          :header-props="{ 
                            sortByText: 'Ordenar por'
                          }"
                          no-data-text="No se encontraron registros"
                          loading-text="Cargando..."
                          :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            prevIcon: 'mdi-chevron-left',
                            nextIcon: 'mdi-chevron-right',
                            'items-per-page-text': 'Registros por página',
                            pageText: '{0}-{1} de {2}'
                          }"
                          class="elevation-1"
                        >
                          <template v-slot:item="{ item }">
                          <tr>
                            <td>{{ item.rolActor }}</td>
                            <td>{{ item.paisOrganizacion }}</td>
                            <td>
                             <!--  <v-btn
                                class=" ma-2
                                btn-bg-light
                                blue-grey--text
                                lighten-2--text
                                font-weight-medium
                                text-capitalize"
                                small
                                depressed
                                color="blue-grey lighten-5"
                                :disabled="btnEliminarRolActorDisabled"
                                @click="eliminarRolActor(item.id)"
                              >
                                <v-icon left>mdi-delete</v-icon> Eliminar
                              </v-btn> -->

                             <!--  <v-btn v-if="item.estadoId===1"
                                class=" ma-2
                                btn-bg-light
                                blue-grey--text
                                lighten-2--text
                                font-weight-medium
                                text-capitalize"
                                small
                                depressed
                                color="blue-grey lighten-5"
                                @click="mostrarFormularioProductos(item.id)"
                              >
                                <v-icon left>mdi-plus</v-icon> Agregar Productos
                              </v-btn> -->
                            </td>
                          </tr>
                        </template>
                        </v-data-table>
                      </template>
                      </v-col>
                  </v-row>
                

              </v-card-text>
          </v-card>
        </v-card-text>
        

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>

    <!--end:: modal registro-->


    <!--begin:: dialog registro actores-->
    <!--- <v-dialog
      v-model="dialogRegistroActores"
      max-width="900"
      transition="dialog-bottom-transition"
      persistent
      scrollable
    >
      <v-card tile>
        <v-card-title>
          Registrar Roles y Actores

          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroActoresLoading"
            @click="
              dialogRegistroActores = false;
              resetFormActores();
            "
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="d-none"></v-divider>

        <v-card-text class="pl-8 pr-8 pt-5">

          

        </v-card-text>
      </v-card>
    </v-dialog>-->
    <!--fin:: dialog registro actores-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
    ></DialogLoader>
    <!---->

    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->
  </div>
</template>

<script>
import Vue from 'vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DialogLoader from "@/view/content/DialogLoader";
import moment from "moment";

import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import validations from "@/core/untils/validations.js";
import DownloadFile from "@/core/untils/downloadfile.js";
import { OBTENER_MODALIDADES } from "@/core/services/store/catalogos/modalidadesnegociaciondirecta/modalidadnegociaciondirecta.module";

import { OBTENER_PAISES_FUENTES_BILATERALES } from "@/core/services/store/catalogos/paises/pais.module";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import {
  OBTENER_NEGOCIACIONES,
  REGISTRAR_NEGOCIACION_DIRECTA,
  OBTENER_NEGOCIACION,
  ACTUALIZAR_NEGOCIACION,
  ENVIAR_NEGOCIACION
} from "@/core/services/store/negociaciones/negociaciondirecta.module";
import { OBTENER_ROLES_ACTORES_CSS, REGISTRAR_ROL_ACTOR_CSS, ELIMINAR_ROL_ACTOR_CSS } from "@/core/services/store/negociaciones/iniciativas/rolesactores/asignacionrolactor.module";

import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
moment.locale('es');
Vue.filter("formatDate", function(value) {
    if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
    }
});

export default {
  name: "AdminNegociacionComixtaExterna",
  data() {
    return {
      accion: 1,
      tableLoading:false,
      validForm: false,
      ddRolesLoading: false,
      validFormActores:false,
      dialogRegistro: false,
      dialogRegistroActores: false,
      dialogLoaderVisible: false,
      dialogEnvio: false,
      dialogLoaderText: "",
      switchItemEstado: true,
      filtro: "",
      dialog: false,
      modalTitle: "Registrar negociación directa",
     
      ddModalidadesLoading: false,
      ddPaisesLoading: false,
      btnRegistroText: "Guardar",
      btnRegistroLoading: false,
      btnEnvioLoading: false,
      btnRegistroActoresLoading: false,
      btnRegistroCoberturaLoading: false,
      tableCoberturasLoading: false,
      documentoMecanismo: [],
      archivoExiste: false,
      negociacionesDirectas: [],
      modalidades: [],
      paises: [],
      items: [],
      mecanismosEjecucion: [],
      fechaAprobacion: '',
      iniciaVigencia: '',
      finalizaVigencia: '',
      archivoCargado: false,
      datosItem: {
        id: 0,
        tipoNegociacionId: 2,
        modalidadId: 1,
        paisId: 0,
        intrumentoFormalizacion: "",
        pathDocumentoFormalizacion: "",
        fechaAprobacion: "",
        estadosId: 1,
        finalizaVigencia: "",
        iniciaVigencia: "",
        usuarioCreacion: "admin"
      },
      archivoReemplazado:false,
      negociacionIdSeleccionada:0,
      tableRolesActoresLoading: false,
      btnRegistroRolActorLoading: false,
      btnEliminarRolActorDisabled: false,
      rolesActores: [],
      datosRolActor: {
        rolesActorId: 0,
        paisId: 0,
        estadosId: 1,
        usuarioCreacion: "admin",
        negociacionId:0,
      },
       estado: "",
      estados: [
        { text: "Todos", value: null },
        { value: 1, text: "Registrada" },
        { value: 2, text: "En Verificación" },
        { value: 3, text: "Aprobada" },
        { value: 4, text: "En Ejecución" },
        { value: 5, text: "Finalizada" },
        { value: 6, text: "Cancelada" },
        { value: 8, text: "Rechazada" },
        { value: 9, text: "Corrección" }
      ],
      modalidadId: 0,
      idNegociacionRegistrada: 0,
      ...validations
    };
  },
  components: {
    DialogLoader,
    SnackAlert,
    DivisorColor
  },
  methods: {
    //Obtener paises
    async obtenerPaises() {
      this.paises = [];
      this.ddPaisesLoading = true;

      this.$store
        .dispatch(OBTENER_PAISES_FUENTES_BILATERALES)
        .then(() => {
          this.paises = this.$store.state.pais.paises;
          //console.log(this.paises);
          //console.log(this.items)
          this.ddPaisesLoading = false;
        })
        .catch(() => {
          this.ddPaisesLoading = false;
        });
    },

    //Obtener modalidades
    async obtenerModalidades() {
      this.modalidades = [];
      this.ddModalidadesLoading = true;

      this.$store
        .dispatch(OBTENER_MODALIDADES)
        .then(() => {
          this.modalidades = this.$store.state.modalidadnegociaciondirecta.modalidades;
          //console.log(this.modalidades);
          //console.log(this.items)
          this.ddModalidadesLoading = false;
        })
        .catch(() => {
          this.ddModalidadesLoading = false;
        });
    },


    //Obtener los Mecanismos de ejecucion
    async obtenerMecanismosEjecucion() {
      this.mecanismosEjecucion = [];

      await this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'MecanismoEjecucion/all/1'})
          .then(res => {
          if (res.status === 200) {
              this.mecanismosEjecucion = res.data;
          }
          })
          .catch(() => {
              this.mecanismosEjecucion = [];
          });
      },

    //Obtener los roles para registro
    async obtenerRoles() {
      /*this.roles = [
        { id: 1, nombreRol: "Rol 1" },
        { id: 2, nombreRol: "Rol 2" },
        { id: 3, nombreRol: "Rol 3" }
      ];*/

       this.roles=[];

       await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'RolesActores/all/1'})
        .then(res => {
          if (res.status === 200) {
            this.roles = res.data;
          }
        })
        .catch(() => {
            this.roles = [];
        });
    },

    prepararEnvio(id) {
      this.datosItem.id = id;
      this.dialogEnvio = true;
    },

    async enviarNegociacion() {
      let idNegociacion = this.datosItem.id;
      this.btnEnvioLoading = true;

      this.$store
        .dispatch(ENVIAR_NEGOCIACION, {id:idNegociacion, nuevoEstado: 2})
        .then(res => {
          this.btnEnvioLoading = false;
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerItems();
            this.dialogEnvio = false;
            this.resetForm();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEnvioLoading = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
          this.btnEnvioLoading = false;
        });
    },

    filtroEstado(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.estado.value) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.estado.text;
    },

    modalNuevo() {
      this.action = 1;
      this.modalTitle = "Registrar Negociación Directa";
      this.btnRegistroText = "Registrar Negociación";
      this.switchItemEstado = true;
      this.archivoExiste = false;
      this.dialogRegistro = true;
    },

    cerrarModalRegistro() {
      //this.dialogRegistro = false;
    },

    //Mostrar el dialogo para la suspension
    mostrardialogEnvio(nombreProyecto) {
      this.proyectoSeleccionado = nombreProyecto;
      this.dialogEnvio = true;
    },

    resetForm() {
      this.datosItem = {
        id: 0,
        modalidadId: 1,
        paisesId: 0,
        pathDocumentoMecanismo: "",
        fechaAprobacion: "",
        finalizaVigencia: "",
        iniciaVigencia: "",
        intrumentoFormalizacion: "",
        estadosId: 1,
        usuarioCreacion: "admin"
      };
      this.datosItem.fechaAprobacion ="";
      this.datosItem.iniciaVigencia = "";
      this.datosItem.finalizaVigencia  = "";
      this.fechaAprobacion ="";
     
      this.archivoExiste = false;
      if (this.documentoMecanismo && this.documentoMecanismo.size) {
        //console.log("aui")
        //document.querySelector('#respaldoFile').value = '';
        //this.$refs.respaldoFile.reset();
        this.documentoMecanismo = [];
        this.$refs.respaldoFile.reset();
        //this.$refs['respaldoFile'][0].reset();
      }
      this.documentoMecanismo = [];

      this.switchItemEstado = true;
      this.accion = 1;
      // console.log(this.accion)
    },

    /// Obtener los items de negociaciones
    async obtenerItems() {
      this.items = [];
      this.tableLoading = true;

      await this.$store
        .dispatch(OBTENER_NEGOCIACIONES, {estado: 0, tipo:2})
        .then(res => {
          if(res.status===200){
           // console.log(res)
            this.items = this.$store.state.negociacionDirecta.negociacionesDirectas;
          }
          //console.log(this.items);
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },


    //Registrar la informacion general del proyecto
    async registrarItem(){
      this.btnRegistroLoading = true;
      if(this.accion===1){
          this.cargarArchivo().then(() => {
          
              //?Validar que el archivo fue cargado
              if(this.archivoCargado){
                  this.registrarNegociacion();
              } else {
                  this.btnDatosGeneralesLoading=false;
              }
          })
        } else if(this.accion===2){
          if(this.archivoReemplazado===true){
            this.cargarArchivo().then(() => {
          
              //?Validar que el archivo fue cargado
              if(this.archivoCargado){
                  this.registrarNegociacion();
              } else {
                  this.btnDatosGeneralesLoading=false;
              }
            });
          } else {
            this.registrarNegociacion();
          }
        }
    },

    async cargarArchivo(){
      const file = this.documentoMecanismo;

      await 
          this.$store.dispatch(CARGAR_ARCHIVO, {file:file, path: '\\css\\respaldos\\'})
          .then(res => {
              //console.log(res)
              if(res.status===200){
                  this.archivoCargado = true;
                  this.datosItem.pathDocumentoFormalizacion = res.pathArchivo;
                  return true;
              }
              else {
                  this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                  this.archivoCargado = false;
                  return false;
              }
          })
          .catch(() => {
            //console.log(error)
              return false;
              
          });
    },

    ///Registro de negociacion
    registrarNegociacion() {

      this.datosItem.tipoNegociacionId= 2;
      this.idNegociacionRegistrada = 0;
      //!Asignar el estado a datos empresa segun lo seleccionado en el swithc del formulario
      this.switchItemEstado
        ? (this.datosItem.estadosId = 1)
        : (this.datosItem.estadosId = 2);

      this.datosItem.usuarioCreacion ="admin";

      this.datosItem.fechaAprobacion = moment(this.fechaAprobacion, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosItem.iniciaVigencia = moment(this.iniciaVigencia, "DD-MM-YYYY").format("YYYY-MM-DD");
      this.datosItem.finalizaVigencia = moment(this.finalizaVigencia, "DD-MM-YYYY").format("YYYY-MM-DD");

      //?Si action es 1 se realiza el registro
      if (this.accion === 1) {
        this.$store
          .dispatch(REGISTRAR_NEGOCIACION_DIRECTA, this.datosItem)
          .then(res => {
            this.btnLoading = false;
            //this.dialogLoaderVisible = false;
            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
              this.idNegociacionRegistrada = res.data;
              this.archivoReemplazado = false;
              this.obtenerItems();
              //this.dialogRegistro = false;
              this.datosItem.id = res.data;
              this.dialogTitulo = `Actualizar negociación`;
              this.btnRegistroText = `Actualizar negociación`;
              this.accion=2;
              this.resetForm();
            } else {
              this.$refs.snackalert.SnackbarShow("warning","Alerta",res.message);
            }
            this.btnRegistroLoading = false;
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
          });
      }
      //?Si action es 2 se realiza la actualizacion
      else if(this.accion === 2){
        this.$store
          .dispatch(ACTUALIZAR_NEGOCIACION, {
            datosItem: this.datosItem,
            id: this.datosItem.id
          })
          .then(res => {
            this.btnLoading = false;
            this.dialogLoaderVisible = false;
            this.btnRegistroLoading = false;

            if (res.status === 200) {
              this.$refs.snackalert.SnackbarShow("success","Mensaje",res.message);
              this.obtenerItems();
              this.dialogRegistro = false;
              this.resetForm();
              this.archivoReemplazado = false;
            } else {
              this.$refs.snackalert.SnackbarShow("warning", "Alerta",res.message);
            }
          })
          .catch(() => {
            this.$refs.snackalert.SnackbarShow("warning","Alerta",`Ha ocurrido un error durante el registro.`);
          });
      }
    },

    //!Obtener datos de un item especifico
    obtenerDatosItem(Id) {
      this.dialogLoaderVisible = true;
      this.dialogLoaderText = "Obteniendo información...";
      this.dialogTitulo = `Actualizar negociación`;
      this.btnRegistroText = `Actualizar negociación`;
      this.modalTitle= "Visualizar negociación";
      //!Pre seleccionar el tab de datos de la empresa
      this.$store
        .dispatch(OBTENER_NEGOCIACION, Id)
        .then(res => {
          //console.log(res);
          if (res.status === 200) {
            this.datosItem = this.$store.state.negociacionDirecta.negociacionDirecta;
            this.fechaAprobacion =  moment(this.datosItem.fechaAprobacion, "YYYY-MM-DD").format("DD/MM/YYYY"); 
            this.iniciaVigencia = moment(this.datosItem.iniciaVigencia, "YYYY-MM-DD").format("DD/MM/YYYY");
            this.finalizaVigencia = moment(this.datosItem.finalizaVigencia, "YYYY-MM-DD").format("DD/MM/YYYY"); 
            this.datosItem.estadoId === 1
              ? (this.switchItemEstado = true)
              : (this.switchItemEstado = false);
            this.accion = 2;
            this.dialogRegistro = true;
            //this.verificarArchivo(this.$apiUrl+this.datosItem.pathDocumentoFormalizacion);
            this.idNegociacionRegistrada=Id;
            this.obtenerRolesActores(Id);

          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
            );
          }
          this.dialogLoaderVisible = false;
        })
        .catch(() => {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error inesperado, por favor, póngase en contacto con el administrador del sistema.`
          );
          this.dialogLoaderVisible = false;
        });
    },



    //Obtener las asignaciones de roles y actores de la iniciativa
    async obtenerRolesActores(idNegociacion){
       this.rolesActores = [];
       this.tableRolesActoresLoading = true;
     
      await this.$store
        .dispatch(OBTENER_ROLES_ACTORES_CSS, {id: idNegociacion})
        .then(res => {
        //  console.log(res)
          if(res.status===200){
            this.rolesActores = res.data;
            //this.datosItem.area === "Rural"  ? this.areaSeleccionada = {id: 1, nombreArea: this.datosItem.area}  : this.areaSeleccionada = {id: 2, nombreArea: this.datosItem.area};
          }
            this.tableRolesActoresLoading = false;
        })
        .catch(() => {
          this.tableRolesActoresLoading = false;
        });

      
    },

    
    //Mostrar el modal para roles y actores
    mostrarModalRolesActores(idNegociacion, modalidadId){
      this.negociacionIdSeleccionada = idNegociacion;
      this.modalidadId = modalidadId;
      this.obtenerRolesActores(idNegociacion).then(()=>{
        this.dialogRegistroActores = true;
      });
    },



    //Registrar actores
    async registrarActor() {

        let oferentes = 0;
        let receptores = 0;
        let ambos = 0;

        let registro = false;
        //Definir las reglas para el registro de actores y roles 

        // Obtener la cantidad de registros de oferentes, receptores y ambos del array de rolesActores
        oferentes = this.rolesActores.filter(item => item.rolesActorId === 1).length;
        receptores = this.rolesActores.filter(item => item.rolesActorId === 2).length;
        ambos = this.rolesActores.filter(item => item.rolesActorId === 3).length;


       // console.log(oferentes + " " + receptores + " " + ambos + "  rolselec " + this.datosRolActor.rolesActorId)

        //Vlidar que no se repita el país
        let buscarPais = this.rolesActores.filter(item => item.paisId === this.datosRolActor.paisId);

        //console.log(buscarPais)

        if(buscarPais.length > 0){
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", "El país que intenta ingresar, ya se encuentra registrado como actor!");
            registro = false;
            return;
        }

        // 1 Si es bilateral solo se puede seleccionar un oferente(1) y un receptor(2); también 2 veces (ambos(3))
        this.modalidadId=1;
        if(this.modalidadId === 1){
          
          

          if(this.datosRolActor.rolesActorId === 1 && (oferentes > 0 || ambos > 1)) {
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Solo se puede registrar un oferente para la modalidad bilateral");
            registro = false;
            return;
          }

          //Validar cuando el que se desea registrar es receptor
          if(this.datosRolActor.rolesActorId === 2 && (receptores > 0 || ambos > 1)) {
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Solo se puede registrar un oferente para la modalidad bilateral");
            registro = false;
            return;
          }

          //Validar cuando el que se desea registrar es ambos
          if(this.datosRolActor.rolesActorId === 3 && (oferentes > 0 || receptores > 0 ||  ambos > 1)) {
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Solo se puede registrar un oferente para la modalidad bilateral");
            registro = false;
            return;
          }

         

        }


        


        // 2 Si es regional solo se puede seleccionar un oferente(1) y n numero de receptores receptor(2);  (ambos(3) ninguna vez)
        
        if(this.modalidadId === 2){
          
          //Validar que no seleccione "Ambos"
          /* if(this.datosRolActor.rolesActorId === 3){
             this.$refs.snackalert.SnackbarShow("warning", "Alerta", "No se permite registrar 'Ambos' para la modalidad regional");
            registro = false;
            return;
          } */

          //Validar cuando el que se desea registrar es oferente
          if(this.datosRolActor.rolesActorId === 1 && (oferentes > 0)) {
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Solo se puede registrar un oferente para la modalidad regional");
            registro = false;
            return;
          }


        }

        // 3 Si es triangular solo se puede seleccionar dos oferentes(1), un receptor(2) y ningun (ambos(3))
        
        if(this.modalidadId === 3){

          //Validar cuando el que se desea registrar es oferente
          let contadorPais = this.rolesActores.filter(item => item.paisId === this.datosRolActor.paisId).length;

          if(contadorPais > 0){
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Ya se encuentra registrado el país que intenta agregar!");
            registro = false;
            return;
          }
          
          //Validar que no seleccione "Ambos"
          if(this.datosRolActor.rolesActorId === 3){
             this.$refs.snackalert.SnackbarShow("warning", "Alerta", "No se permite registrar 'Ambos' para la modalidad triangular");
            registro = false;
            return;
          }

          //Validar cuando el que se desea registrar es oferente
          if(this.datosRolActor.rolesActorId === 1 && (oferentes > 1)) {
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Solo se pueden registrar dos oferentes para la modalidad triangular");
            registro = false;
            return;
          }

          //Validar cuando el que se desea registrar es receptor
          if(this.datosRolActor.rolesActorId === 2 && (receptores > 0)) {
            this.$refs.snackalert.SnackbarShow("warning", "Alerta", "Solo se puede registrar un oferente para la modalidad bilateral");
            registro = false;
            return;
          }

        }



        registro = true;

        this.datosRolActor.tiposFuenteCooperanteId = 1;
        this.datosRolActor.negociacionId = this.datosItem.id;



        //Si pasa las reglas registrar el rolesactor
        if(registro === true){
            this.btnRegistroRolActorLoading=true;
           // this.datosRolActor.negociacionId = this.negociacionIdSeleccionada;
            await this.$store
            .dispatch(REGISTRAR_ROL_ACTOR_CSS, { datos: this.datosRolActor })
            .then(res => {
                //console.log(res)
                if(res.status===200){
                   // this.obtnenerItems();
                   // this.dialog=false;
                    this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                   
                  //  this.switchItemEstado = true;
                  //  this.datosIndicador.detalleIndicador = '';
                    this.btnRegistroRolActorLoading=false;
                    this.obtenerRolesActores(this.datosItem.id);
                    this.obtenerItems();
                } else {
                    this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
                }
                this.btnRegistroRolActorLoading=false;
            })
            .catch(error => {
                //console.log(error)
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
                this.btnRegistroRolActorLoading=false;
            });
        }



    },


     //Eliminar el rolactor
    async eliminarRolActor(id){
       this.btnEliminarRolActorDisabled=true;
       // this.datosRolActor.iniciativaId = this.iniciativaId;
        await this.$store
        .dispatch(ELIMINAR_ROL_ACTOR_CSS, id)
        .then(res => {
            //console.log(res)
            if(res.status===200){
                // this.obtnenerItems();
                // this.dialog=false;
                this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.message);
                
              //  this.switchItemEstado = true;
              //  this.datosIndicador.detalleIndicador = '';
                this.btnEliminarRolActorDisabled=false;
                this.obtenerRolesActores(this.datosItem.id);
            } else {
                this.$refs.snackalert.SnackbarShow('warning', 'Alerta', res.message);
            }
            this.btnEliminarRolActorDisabled=false;
        })
        .catch(error => {
            //console.log(error)
            this.$refs.snackalert.SnackbarShow('warning', 'Alerta', error);
            this.btnRegistroRolActorLoading=false;
        });
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (e.type != "application/pdf" && e.type != "image/jpeg") {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 10) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (10Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#respaldoFile").value = "";
          this.$refs.respaldoFile.reset();
          return false;
        }

        if(this.accion===2){
          this.archivoReemplazado=true;
        }

        this.documentoMecanismo = e;
      }
    },

     //!Validar que el arhcivo exista
      verificarArchivo(path){
          //console.log(path)
          //try{
              var xhr = new XMLHttpRequest();
              xhr.open('HEAD', path, false);
              xhr.send();
              xhr.status===200 ? this.archivoExiste = true : this.archivoExiste = false;
              return xhr.status !== 404;
         /* } catch(err){
              console.log(err)
          }*/

          
      },

   
    //Descargar el archivo
      descargarArchivo(path){
        DownloadFile.download(path);
      }
  },
  created() {
    this.obtenerModalidades();
    this.obtenerItems();
    this.obtenerPaises();
    this.obtenerMecanismosEjecucion();
    this.obtenerRoles();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard" },
      { title: "Negociaciones" },
      { title: "Comixta" }
    ]);
  },

  computed: {
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "codigo"
        },
         {
          text: "Título",
          align: "start",
          sortable: true,
          value: "titulo" 
        },
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "descripcion" 
        },
        
        {
          text: "Modalidad Ejecución",
          align: "start",
          sortable: true,
          value: "modalidad"
        },
        {
          text: "País",
          align: "start",
          sortable: true,
          value: "pais"
        },
        {
          text: "Fecha Aprobación",
          align: "start",
          sortable: true,
          value: "fechaAprobacion"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "estado",
          filter: this.filtroEstado
        },
        {
          text: "actiones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },

     headersActores() {
      return [
        {
          text: "Rol",
          align: "start",
          sortable: false,
          value: "Rol"
        },
        {
          text: "País",
          align: "start",
          sortable: true,
          value: "pais"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    },
  }
};
</script>
